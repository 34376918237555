import api from '../../helpers/api';

const authServices = {
  me: () => {
    return api.get('/admin/me');
  },
  login: (email: string, password: string, remember?: boolean) => {
    return api.post(
      '/admin/signin',
      { email, password },
      { params: { remember } }
    );
  },
  logout: () => {
    return api.get('/admin/logout');
  },
  requestRecovery: (email: string) => {
    return api.post('/admin/forgot_password', { email });
  },
  checkRecovery: (token: string) => {
    return api.get('/admin/update_password/' + token);
  },
  postRecovery: (token: string, password: string) => {
    return api.put(`/admin/update_password/${token}`, { password });
  },
};

export default authServices;
